import {useNavigate} from "react-router-dom";
import NavbarItem from "../NavbarItem/NavbarItem";
import {GrGallery} from "react-icons/gr";
import {MdLogout} from "react-icons/md";
import {FaCat} from "react-icons/fa";
import React from "react";
import {userService} from "../../service/User/UserService";

/**
 * Composant permettant de représenter la barre de navigation
 * de la page de profil.
 *
 * @constructor
 */
const ProfileNavbar = () => {

    const navigate = useNavigate();

    /**
     * Méthode permettant d'ouvrir la page
     * de la galerie.
     */
    const handleGalleryOpen = () => {

        navigate("/gallery");
    };

    /**
     * Méthode permettant d'ouvrir la page
     * des souvenirs.
     */
    const handleMemoriesOpen = () => {

        navigate("/memories");
    };

    /**
     * Méthode permettant de gérer la déconnexion
     * de l'utilisateur.
     */
    const handleLogout = () => {

        userService.logoutUser().then(() => {

            navigate("/login");
        });
    };

    return (

        <div className="fixed bottom-0 left-0 right-0 z-50 flex justify-center space-x-6 p-2 bg-white border-t border-gray-50">
            <NavbarItem icon={<GrGallery/>} onClick={handleGalleryOpen}/>
            <NavbarItem icon={<FaCat/>} onClick={handleMemoriesOpen}/>
            <NavbarItem icon={<MdLogout/>} onClick={handleLogout}/>
        </div>
    );
};

export default ProfileNavbar;