type TextInputProps = {
    label: string;
    value: string;
    onChange: Function;
    valid: boolean;
    disabled?: boolean;
    type?: string;
};

/**
 * Composant permettant de représenter un input
 * de texte.
 *
 * @param props props pour l'input de texte.
 * @constructor
 */
const TextInput = (props: TextInputProps) => {

    const getStyle = () => {

        const baseStyle = "border rounded-md py-2 px-5";

        if (!props.valid) {

            return `${baseStyle} border-red-500`;
        }

        if (props.disabled) {

            return `${baseStyle} border-gray-100 text-gray-400`;
        }

        return baseStyle;
    };

    return (

        <div className="flex flex-col space-y-1">
            <label>{ props.label }</label>

            <input
                className={ getStyle() }
                value={ props.value }
                onChange={(e) => props.onChange(e.target.value)}
                disabled={ props.disabled }
                type={ props.type }
            />
        </div>
    );
};

export default TextInput;