import ProfileForm from "../../component/ProfileForm/ProfileForm";
import ProfileNavbar from "../../component/ProfileNavbar/ProfileNavbar";

/**
 * Page permettant aux utilisateurs de gérer leur profil.
 * @constructor
 */
const Profile = () => {

    return (
        <div className="relative">
            <ProfileNavbar />
            <ProfileForm />
        </div>
    );
};

export default Profile;