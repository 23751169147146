import {Memory} from "../../model/Memory/Memory";
import React from "react";
import MemoryImageCarousel from "../ImageCarousel/MemoryImageCarousel";
import {dateUtils} from "../../service/Utils/DateUtils";
import {useSelector} from "react-redux";
import {selectConnectedUser} from "../../store/User/UserSlice";
import {objectUtils} from "../../service/Utils/ObjectUtils";
import store from "../../store/Store";
import {openMemoryUpdateDialog} from "../../store/Memory/MemorySlice";

type MemoryCardProps = {
    memory: Memory;
};

/**
 * Composant permettant de représenter une carte
 * d'un souvenir.
 *
 * @param props props de la card.
 * @constructor
 */
const MemoryCard = (props: MemoryCardProps) => {

    const memory = props.memory;

    const memoryDate = dateUtils.convertDateToFrenchFormat(memory.date);
    const memoryCreationDate = dateUtils.convertDateToFrenchFormat(memory.creationDate);

    const user = useSelector(selectConnectedUser);

    /**
     * Méthode permettant de savoir si oui ou
     * non le souvenir peut être mis à jour.
     */
    const isUpdatable = () => {

        return objectUtils.deepEquals(user, memory.author);
    };

    /**
     * Méthode permettant d'ouvrir la dialog de mise à jour
     * d'un souvenir.
     */
    const handleMemoryUpdateDialogOpen = () => {

        store.dispatch(
            openMemoryUpdateDialog(memory)
        );
    };

    return (
        <div className="bg-white p-8 space-y-4 font-raleway md:w-1/2 xl:w-1/3">

            <div className="flex justify-between">

                <h2 className="text-xl font-semibold">{ memory.title }</h2>
                <h2 className="text-xl font-semibold">{ memoryDate }</h2>
            </div>

            <MemoryImageCarousel images={ memory.images } />

            <p className="text-sm text-gray-700">{ memory.description }</p>

            <hr />

            <div className="flex justify-between">
                <p className="text-xs">Publié le <span className="font-semibold">{ memoryCreationDate }</span> par <span className="font-semibold">{ memory.author.firstName } { memory.author.lastName }</span></p>

                {
                    isUpdatable() && <button className="text-xs hover:cursor-pointer hover:underline" onClick={ handleMemoryUpdateDialogOpen }>Modifier</button>
                }
            </div>
        </div>
    );
};

export default MemoryCard;