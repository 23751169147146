import {useState} from "react";
import Image from "../Image/Image";
import { RxCross2 } from "react-icons/rx";

type GalleryImageProps = {
    fileName: string;
};

/**
 * Composant permettant de représenter une image de galerie.
 *
 * @param props props du composant.
 * @constructor
 */
const GalleryImage = (props: GalleryImageProps) => {

    const [isOpen, setIsOpen] = useState(false);

    /**
     * Méthode permettant d'ouvrir l'image.
     */
    const handleImageOpen = () => {

        setIsOpen(true);
    };

    /**
     * Méthode permettant de fermer l'image.
     */
    const handleImageClose = () => {

        setIsOpen(false);
    }

    const handleStopPropagation = (event: { stopPropagation: () => void; }) => {

        event.stopPropagation();
    }

    const style = "max-w-full max-h-full hover:cursor-default";

    return (
        <div>

            <div className="hover:cursor-pointer" onClick={ handleImageOpen }>

                <Image fileName={ props.fileName } />
            </div>

            {
                isOpen &&

                <div className="fixed inset-0 flex bg-black bg-opacity-80 hover:cursor-pointer justify-center p-10 space-x-2 z-50" onClick={ handleImageClose }>

                    <Image fileName={ props.fileName } className={ style } onClick={ handleStopPropagation } />
                    <RxCross2 className="hover:cursor-pointer text-white hover:text-gray-400 text-2xl" onClick={handleImageClose}/>
                </div>
            }
        </div>
    );
};

export default GalleryImage;