import React from "react";
import {RxCross2} from "react-icons/rx";

type ImagePrevisualizerProps = {
    images: File[];
    onImageDeletion?: Function;
};

/**
 * Composant permettant de représenter la prévisualisation
 * des images avec la possibilité de les supprimer.
 *
 * @param props props du composant.
 * @constructor
 */
const ImagePrevisualizer = (props: ImagePrevisualizerProps) => {

    const handleRemoveImage = (index: number) => {

        if (props.onImageDeletion) {

            props.onImageDeletion(index);
        }
    };

    return (
        <div>
            {props.images.length > 0 && (
                <div className="mb-4">
                    <h3 className="text-lg font-medium mb-2">Aperçu des images :</h3>
                    {/* Ajout d'une largeur minimum pour éviter que les images soient compressées */}
                    <div className="flex gap-2 overflow-x-auto flex-nowrap max-w-full">
                        {props.images.map((image, index) => (
                            <div key={index} className="relative flex-shrink-0">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`preview-${index}`}
                                    className="h-32 w-auto object-cover rounded-lg"
                                />
                                {
                                    props.onImageDeletion &&
                                    <RxCross2 className="absolute top-1 right-1 hover:cursor-pointer text-white hover:text-gray-400 text-xl" onClick={() => handleRemoveImage(index)}/>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImagePrevisualizer;
