/**
 * Service utilitaire permettant de gérer les dates.
 */
class DateUtils {

    /**
     * Méthode permettant de convertir une date du format "YYYY-MM-DD"
     * au format "DD/MM/YYYY".
     *
     * @param date date à convertir.
     */
    convertDateToFrenchFormat(date: Date): string {

        date = new Date(date);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
}

export const dateUtils = new DateUtils();