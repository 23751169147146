import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Memory} from "../../model/Memory/Memory";
import {RootState} from "../Store";

type MemoryState = {
    isMemoryFormDialogOpen: boolean,
    memories: Memory[],
    selectedMemory: Memory | null;
};

const initialState: MemoryState = {
    isMemoryFormDialogOpen: false,
    memories: [],
    selectedMemory: null,
};

export const memorySlice = createSlice({
    name: "memory",
    initialState,
    reducers: {
        openMemoryCreationDialog: (state) => {
            state.isMemoryFormDialogOpen = true;
            state.selectedMemory = null;
        },
        closeMemoryCreationDialog: (state) => {
            state.isMemoryFormDialogOpen = false;
            state.selectedMemory = null;
        },
        openMemoryUpdateDialog: (state, action: PayloadAction<Memory>) => {
            state.isMemoryFormDialogOpen = true;
            state.selectedMemory = action.payload;
        },
        closeMemoryUpdateDialog: (state) => {
            state.isMemoryFormDialogOpen = false;
            state.selectedMemory = null;
        },
        setMemories: (state, action: PayloadAction<Memory[]>) => {
            state.memories = action.payload;
        },
    }
});

export const { openMemoryCreationDialog, closeMemoryCreationDialog, setMemories, openMemoryUpdateDialog, closeMemoryUpdateDialog } = memorySlice.actions;

export const selectIsMemoryFormDialogOpen = (state: RootState) => state.memory.isMemoryFormDialogOpen;
export const selectMemories = (state: RootState) => state.memory.memories;
export const selectSelectedMemory = (state: RootState) => state.memory.selectedMemory;

export default memorySlice.reducer;