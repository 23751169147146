import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectConnectedUser } from "../../store/User/UserSlice";
import { userService } from "../../service/User/UserService";
import { useEffect, useState } from "react";

const PrivateRoute = () => {
    const user = useSelector(selectConnectedUser);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchUser = async () => {

            try {

                if (!user) {

                    await userService.getConnectedUser();
                }
            } catch (error) {

                console.error("Error fetching user:", error);
            } finally {

                setLoading(false);
            }
        };

        fetchUser();
    }, [user]);

    if (loading) {

        return <div>Chargement...</div>;
    }

    return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
