import {ChangeEvent} from "react";

type DatePickerProps = {
    label: string;
    value: string;
    onChange: Function;
    valid: boolean;
    disabled?: boolean;
};

/**
 * Composant permettant de représenter un date picker.
 *
 * @param props props du composant.
 * @constructor
 */
const DatePicker = (props: DatePickerProps) => {

    const getStyle = () => {

        const baseStyle = "border rounded-md py-2 px-5";

        if (props.valid) {

            return `${baseStyle}`;
        }

        return `${baseStyle} border-red-500`;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

        props.onChange(e.target.value);
    }

    return (
        <div className="flex flex-col space-y-1">
            <label>{ props.label }</label>

            <input
                type="date"
                onChange={ handleChange }
                value={ props.value }
                className={ getStyle() }
            />
        </div>
    );
};

export default DatePicker;