import MemoryList from "../../component/MemoryList/MemoryList";
import MemoryNavbar from "../../component/MemoryNavbar/MemoryNavbar";
import MemoryFormDialog from "../../component/MemoryFormDialog/MemoryFormDialog";

declare global {

    interface Window {
        _env_: any;
    }
}

function Memories() {

    return (
        <div className="flex flex-col h-screen">
            <MemoryList/>
            <MemoryNavbar/>
            <MemoryFormDialog />
        </div>
    );
}

export default Memories;
