import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/Store";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute";
import LoginPage from "./pages/Login/LoginPage";
import Memories from "./pages/Memories/Memories";
import Profile from "./pages/Profile/Profile";
import Gallery from "./pages/Gallery/Gallery";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>

        <BrowserRouter>

            <Provider store={store}>

                <Routes>

                    {/* Routes publiques */}
                    <Route path="/" element={<Navigate to="/memories" />} />
                    <Route path="/login" element={ <LoginPage /> } />

                    {/* Routes privées */}
                    <Route path="/" element={ <PrivateRoute /> }>

                        <Route path="/memories" element={ <Memories /> } />
                        <Route path="/profile" element={ <Profile /> } />
                        <Route path="/gallery" element={ <Gallery /> } />
                    </Route>
                </Routes>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
