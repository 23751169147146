import {MouseEventHandler, ReactElement} from "react";

type NavbarItemProps = {
    icon: ReactElement;
    onClick: MouseEventHandler<HTMLButtonElement> ;
};

/**
 * Composant représentant un élément de barre
 * de navigation.
 *
 * @param props props du composant.
 * @constructor
 */
const NavbarItem = (props: NavbarItemProps) => {

    return (
        <button className="text-2xl text-gray-800 hover:text-gray-600 md:text-4xl" onClick={ props.onClick }>
            { props.icon }
        </button>
    );
};

export default NavbarItem;