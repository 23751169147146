import store from "../../store/Store";
import {openMemoryCreationDialog} from "../../store/Memory/MemorySlice";
import NavbarItem from "../NavbarItem/NavbarItem";
import React from "react";
import {CgProfile} from "react-icons/cg";
import {FiPlus} from "react-icons/fi";
import { GrGallery } from "react-icons/gr";
import {useNavigate} from "react-router-dom";

/**
 * Composant permettant de représenter la barre
 * de navigation de la page des souvenirs.
 *
 * @constructor
 */
const MemoryNavbar = () => {

    const navigate = useNavigate();

    const handleMemoryCreationDialogOpen = () => {

        store.dispatch(
            openMemoryCreationDialog()
        );
    };

    /**
     * Méthode permettant d'ouvrir la page
     * du profil.
     */
    const handleProfileOpen = () => {

        navigate("/profile");
    };

    /**
     * Méthode permettant d'ouvrir la galerie.
     */
    const handleGalleryOpen = () => {

        navigate("/gallery");
    };

    return (
        <div className="flex justify-center space-x-6 2xl:space-x-8 p-2 bg-white border-t border-gray-50 sticky bottom-0">

            <NavbarItem icon={ <GrGallery /> } onClick={ handleGalleryOpen } />
            <NavbarItem icon={ <FiPlus /> } onClick={ handleMemoryCreationDialogOpen } />
            <NavbarItem icon={ <CgProfile /> } onClick={ handleProfileOpen } />
        </div>
    );
};

export default MemoryNavbar;