class ObjectUtils {

    deepEquals(a: any, b: any): boolean {

        if (a === b) {

            return true;
        }

        if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null){

            return false;
        }

        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);

        if (aKeys.length !== bKeys.length) {

            return false;
        }

        for (const aKey of aKeys) {

            if (!bKeys.includes(aKey) || !this.deepEquals(a[aKey], b[aKey])) {

                return false;
            }
        }

        return true;
    };
}

export const objectUtils = new ObjectUtils();