import React, { useState, useRef, useEffect } from "react";

interface ValidationCodeInputProps {
    label: string;
    value: string;
    onComplete: Function;
    valid: boolean;
    disabled?: boolean;
}

/**
 * Composant permettant de représenter un code
 * de validation.
 *
 * @param props
 * @constructor
 */
const ValidationCodeInput = (props: ValidationCodeInputProps) => {

    const length = 6;

    const [code, setCode] = useState<string[]>(new Array(length).fill(""));

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {

        if (props.value) {

            const valueArray = props.value.split("").slice(0, length);
            setCode([...valueArray, ...new Array(length - valueArray.length).fill("")]);
        }
    }, [props.value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        const newCode = [...code];

        if (/^\d*$/.test(value)) {
            newCode[index] = value.slice(-1);

            setCode(newCode);

            if (value && index < length - 1) {
                inputsRef.current[index + 1]?.focus();
            }

            if (newCode.every(num => num !== "")) {
                props.onComplete(newCode.join(""));
            }
        }
    };

    const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && code[index] === "" && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

    const getStyle = () => {

        const baseStyle = "border rounded-md py-2 px-5 w-1/2 text-center";

        if (!props.valid) {

            return `${baseStyle} border-red-500`;
        }

        if (props.disabled) {

            return `${baseStyle} border-gray-100 text-gray-400`;
        }

        return baseStyle;
    };

    return (
        <div className="flex flex-col space-y-1">

            <label>{ props.label }</label>

            <div className="flex space-x-2 justify-center">
                {code.map((num, index) => (
                    <input
                        key={index}
                        type="text"
                        value={num}
                        maxLength={1}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        onFocus={handleFocus}
                        ref={(el) => (inputsRef.current[index] = el)}
                        className={ getStyle() }
                    />
                ))}
            </div>
        </div>
    );
};

export default ValidationCodeInput;
