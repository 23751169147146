import api from "../../configuration/Api";
import {setConnectedUser} from "../../store/User/UserSlice";
import store from "../../store/Store";
import {UserLoginParameters} from "../../model/UserLoginParameters/UserLoginParameters";
import {UserUpdateParameters} from "../../model/UserUpdateParameters/UserUpdateParameters";

/**
 * Service permettant de gérer les utilisateurs.
 */
class UserService {

    /**
     * Méthode permettant de récupérer l'utilisateur
     * connecté à l'application.
     */
    async getConnectedUser() {

        return api.get("/auth/connected-user").then((response) => {

            store.dispatch(
                setConnectedUser(response.data)
            );
        });
    }

    /**
     * Méthode permettant de connecter un utilisateur
     * à l'application.
     *
     * @param parameters paramètres de connexion de
     * l'utilisateur.
     */
    async loginUser(parameters: UserLoginParameters) {

        return api.post("/auth/login", parameters).then((response) => {

            userService.getConnectedUser();
        });
    }

    async refreshToken() {

        const response = await api.post("/auth/refresh-token", {});

        return response.data;
    }

    /**
     * Méthode permettant à un utilisateur de se
     * déconnecter de l'application.
     */
    async logoutUser() {

        api.get("/auth/logout").then(() => {

            store.dispatch(
                setConnectedUser(null)
            );
        });
    }

    /**
     * Méthode permettant de mettre à jour
     * un utilisateur.
     *
     * @param parameters paramètres de mise
     * à jour de l'utilisateur.
     */
    async updateUser(parameters: UserUpdateParameters) {

        return api.patch("/user/update", parameters).then((response) => {

            userService.getConnectedUser();
        });
    }

    /**
     * Méthode permettant de faire une demande
     * de réinitialisation de mot de passe.
     *
     * @param login identifiant de l'utilisateur qui
     * souhaite faire une demande de mot de passe.
     */
    async requestPasswordReset(login: string) {

        return api.get("/reset-password/request", { params: { login: login }}).then((response) => {

            return response;
        });
    }

    /**
     * Méthode permettant de réinitialiser le
     * mot de passe.
     *
     * @param login identifiant de l'utilisateur qui
     * souhaite faire une demande de mot de passe.
     * @param password nouveau mot de passe.
     * @param confirmationPassword confirmation du nouveau mot de passe.
     * @param validationCode code de validation.
     */
    async resetPassword(login: string, password: string, confirmationPassword: string, validationCode: number) {

        return api.patch("/reset-password", {}, { params: { login: login, password: password, confirmationPassword: confirmationPassword, validationCode: validationCode }}).then((response) => {

            return response;
        });
    }
}

export const userService = new UserService();