import bouibouiMystereLoginPageImage from "../../assets/images/LoginPage/bouiboui_mystere_login_page_image.jpg";
import LoginForm from "../../component/LoginForm/LoginForm";
import {useState} from "react";
import ResetPasswordForm from "../../component/ResetPasswordAskingForm/ResetPasswordForm";

/**
 * Page de connexion à l'application.
 * @constructor
 */
const LoginPage = () => {

    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

    const handleResetPassword = () => {
        setShowLoginForm(false);
        setShowResetPasswordForm(true);
    }

    const handleOnLogin = () => {
        setShowLoginForm(true);
        setShowResetPasswordForm(false);
    }

    return (
        <div className="flex justify-center h-screen bg-gray-50 font-raleway lg:items-center">

            <div className="flex flex-col lg:flex-row lg:h-2/3 lg:shadow-xl">

                <img src={ bouibouiMystereLoginPageImage } alt="Bouiboui le chat élégant."/>

                <div className="bg-white flex flex-col flex-grow justify-center space-y-4 p-6 lg:w-[30rem] lg:p-8">
                    {
                        showLoginForm && <LoginForm onResetPassword={ handleResetPassword } />
                    }

                    {
                        showResetPasswordForm && <ResetPasswordForm onLogin={ handleOnLogin } />
                    }
                </div>
            </div>
        </div>
    );
};

export default LoginPage;