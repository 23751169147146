import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Image} from "../../model/Image/Image";
import {RootState} from "../Store";

type GalleryState = {
    isGalleryImagesAddingOpen: boolean,
    galleryImages: Image[],
};

const initialState: GalleryState = {
    isGalleryImagesAddingOpen: false,
    galleryImages: [],
};

export const gallerySlice = createSlice({
    name: "gallery",
    initialState,
    reducers: {
        openGalleryImagesAddingDialog: (state) => {
            state.isGalleryImagesAddingOpen = true;
        },
        closeGalleryImagesAddingDialog: (state) => {
            state.isGalleryImagesAddingOpen = false;
        },
        setGalleryImages: (state, action: PayloadAction<Image[]>) => {
            state.galleryImages = action.payload;
        }
    }
});

export const { openGalleryImagesAddingDialog, closeGalleryImagesAddingDialog, setGalleryImages } = gallerySlice.actions;

export const selectIsGalleryImagesAddingDialogOpen = (state: RootState) => state.gallery.isGalleryImagesAddingOpen;
export const selectGalleryImages = (state: RootState) => state.gallery.galleryImages;

export default gallerySlice.reducer;