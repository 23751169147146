import Image from "../Image/Image";

type MemoryImageProps = {
    fileName: string;
};

/**
 * Composant permettant de représenter une image de souvenir.
 *
 * @param props props du composant.
 * @constructor
 */
const MemoryImage = (props: MemoryImageProps) => {

    const style = `flex-none w-full h-full object-contain`;

    return (
        <Image fileName={ props.fileName } className={ style } />
    );
};

export default MemoryImage;