type TextAreaProps = {
    label: string;
    value: string;
    onChange: Function;
    valid: boolean;
    disabled?: boolean;
};

/**
 * Composant permettant de représenter les zones
 * de texte.
 *
 * @param props props du composant.
 * @constructor
 */
const TextArea = (props: TextAreaProps) => {

    /**
     * Méthode permettant de récupérer dynamiquement
     * le style du composant.
     */
    const getStyle = () => {

        const baseStyle = "border rounded-md py-2 px-5 resize-none";

        if (props.valid) {

            return `${baseStyle}`;
        }

        return `${baseStyle} border-red-500`;
    };

    return (

        <div className="flex flex-col space-y-1">
            <label>{ props.label }</label>

            <textarea
                className={ getStyle() }
                value={ props.value }
                onChange={(e) => props.onChange(e.target.value)}
                disabled={ props.disabled }
                rows={4}
            />
        </div>
    );
};

export default TextArea;