import api from "../../configuration/Api";

/**
 * Service permettant de gérer les souvenirs de l'application.
 */
class MemoryService {

    private apiUri = "/memory";

    async createMemory(parameters: FormData) {

        return api.post(`${this.apiUri}/create`, parameters)
            .then((response) => {

                return response.data;
            });
    }

    async updateMemory(parameters: FormData) {

        return api.patch(`${this.apiUri}/update`, parameters)
            .then((response) => {

                return response.data;
            });
    }

    async getMemory(id: number) {

        return api.get(`${this.apiUri}/${id}`).then((response) => {

            return response.data;
        });
    }

    async getAllMemories() {

        return api.get(`${this.apiUri}/all`).then((response) => {

            return response.data;
        });
    }
}

export const memoryService = new MemoryService();