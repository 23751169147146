import GalleryNavbar from "../../component/GalleryNavbar/GalleryNavbar";
import GalleryImageList from "../../component/GalleryImageList/GalleryImageList";
import GalleryFormDialog from "../../component/GalleryFormDialog/GalleryFormDialog";
import GalleryHeader from "../../component/GalleryHeader/GalleryHeader";


/**
 * Page permettant de représenter une galerie
 * d'images.
 *
 * @constructor
 */
const Gallery = () => {

    return (
        <div className="flex flex-col h-screen">
            <GalleryHeader />
            <GalleryImageList />
            <GalleryNavbar />
            <GalleryFormDialog />
        </div>
    );
};

export default Gallery;