import api from "../../configuration/Api";

class ImageService {

    private apiUri = "/images";

    async getImageUrl(fileName: string): Promise<string> {

        const response = await api.get(`${this.apiUri}/${fileName}`, {
            responseType: 'blob'
        });

        return URL.createObjectURL(response.data);
    }

    async getImageBlob(fileName: string): Promise<Blob> {

        const response = await api.get(`${this.apiUri}/${fileName}`, {
            responseType: 'blob'
        });

        return response.data;
    }
}

export const imageService = new ImageService();

