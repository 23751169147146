import TextInput from "../TextInput/TextInput";
import React, {FormEvent, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectConnectedUser} from "../../store/User/UserSlice";
import Button from "../SendFormButton/Button";
import {userService} from "../../service/User/UserService";
import { GoPencil } from "react-icons/go";

/**
 * Composant permettant de représenter le formulaire
 * de modification d'un profil.
 * @constructor
 */
const ProfileForm = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");

    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [usernameValid, setUsernameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [confirmationPasswordValid, setConfirmationPasswordValid] = useState(true);

    const [isSending, setIsSending] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isInModification, setIsInModification] = useState(false);

    const user = useSelector(selectConnectedUser);

    useEffect(() => {

        if (user) {
            setFirstName(user.firstName || "");
            setLastName(user.lastName || "");
            setUsername(user.username || "");
            setEmail(user.email || "");
        }
    }, [user]);

    /**
     * Méthode permettant d'ouvrir le mode de modification du formulaire.
     */
    const handleOpenModificationMode = () => {

        setIsInModification(true);
    };

    /**
     * Méthode permettant de fermer le mode de modification du formulaire.
     */
    const handleCancelModificationMode = () => {

        setIsInModification(false);
        resetFormContent();
        resetFormValidation();
        setIsSending(false);
    };

    /**
     * Méthode permettant de réinitialiser le contenu du formulaire.
     */
    const resetFormContent = () => {

        if (user) {
            setFirstName(user.firstName || "");
            setLastName(user.lastName || "");
            setUsername(user.username || "");
            setEmail(user.email || "");
        }

        setPassword("");
        setConfirmationPassword("");
    };

    /**
     * Méthode permettant de réinitialiser la validation du formulaire.
     */
    const resetFormValidation = () => {

        setFirstNameValid(true);
        setLastNameValid(true);
        setUsernameValid(true);
        setEmailValid(true);
        setPasswordValid(true);
        setConfirmationPasswordValid(true);
    };

    /**
     * Méthode permettant de sauvegarder les modifications
     * apportées au profil.
     */
    const handleSaveProfile = (event: FormEvent) => {

        event.preventDefault();

        const isFormValid = validateForm();

        if (isFormValid) {

            setIsSending(true);

            const updateUserParameters = {
                "firstName": firstName,
                "lastName": lastName,
                "username": username,
                "email": email,
                "password": password,
                "matchingPassword": confirmationPassword
            };

            userService.updateUser(updateUserParameters).then(() => {

                handleCancelModificationMode();
            }).catch(() => {

                resetFormValidation();
                setIsSending(false);
                handleError();
            });
        }
    };

    /**
     * Méthode permettant de gérer les erreurs.
     */
    const handleError = () => {

        setErrorMessage("Une erreur est survenue, veuillez réessayer.");
        setShowErrorMessage(true);
    }

    /**
     * Méthode permettant de valider le formulaire
     * de modification du profil.
     */
    const validateForm = () => {

        let isValid = true;

        if (isStringEmpty(firstName)) {

            setFirstNameValid(false);
            isValid = false;
        }

        if (isStringEmpty(lastName)) {

            setLastNameValid(false);
            isValid = false;
        }

        if (isStringEmpty(username)) {

            setUsernameValid(false);
            isValid = false;
        }

        if (isStringEmpty(email)) {

            setEmailValid(false);
            isValid = false;
        }

        if (password !== confirmationPassword) {

            isValid = false;
            setPasswordValid(false);
            setConfirmationPasswordValid(false);
            setErrorMessage("Les mots de passe ne correspondent pas.");
            setShowErrorMessage(true);
        }

        return isValid;
    };

    /**
     * Méthode permettant de savoir si une chaîne de
     * caractère est vide ou non.
     *
     * @param string la chaîne de caractère à vérifier.
     */
    const isStringEmpty = (string: string) => {

        return !string || string === "";
    }

    return (
        <div className="flex flex-col justify-center min-h-screen bg-white md:items-center">

            <form className="space-y-4 p-6 md:w-1/2 2xl:w-1/3">

                {
                    showErrorMessage &&
                    <div className="border rounded-md border-red-500 bg-red-100 p-2 text-red-600">
                        { errorMessage }
                    </div>
                }

                <TextInput label="Nom" value={ lastName } onChange={ setLastName } valid={ lastNameValid } disabled={ !isInModification } />
                <TextInput label="Prénom" value={ firstName } onChange={ setFirstName } valid={ firstNameValid } disabled={ !isInModification } />
                <TextInput label="Nom d'utilisateur" value={ username} onChange={ setUsername } valid={ usernameValid } disabled={ !isInModification } />
                <TextInput label="Adresse mail" value={ email } onChange={ setEmail } valid={ emailValid } disabled={ !isInModification } />

                {
                    isInModification &&
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <TextInput label="Mot de passe" value={ password } onChange={ setPassword } valid={ passwordValid } type="password" />
                            <TextInput label="Confirmation du mot de passe" value={ confirmationPassword } onChange={ setConfirmationPassword } valid={ confirmationPasswordValid } type="password" />
                        </div>

                        <div className="mt-4 flex justify-end space-x-2">
                            <Button onClick={ handleSaveProfile } label="Sauvegarder" loading={ isSending } />
                            <button className="border rounded-md py-2 px-4 hover:border-gray-300 hover:bg-gray-50" onClick={ handleCancelModificationMode }>Annuler</button>
                        </div>
                    </div>
                }

                {
                    !isInModification &&

                    <div className="flex justify-end items-center space-x-2 hover:cursor-pointer" onClick={ handleOpenModificationMode }>
                        <p className="hover:underline">Modifier votre profil </p>
                        <GoPencil />
                    </div>
                }
            </form>
        </div>
    );
};

export default ProfileForm;