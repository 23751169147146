import {useNavigate} from "react-router-dom";
import NavbarItem from "../NavbarItem/NavbarItem";
import {CgProfile} from "react-icons/cg";
import {FaCat} from "react-icons/fa";
import {FiPlus} from "react-icons/fi";
import React from "react";
import store from "../../store/Store";
import {openGalleryImagesAddingDialog} from "../../store/Gallery/GallerySlice";

/**
 * Composant permettant de représenter la barre de navigation
 * de la galerie.
 *
 * @constructor
 */
const GalleryNavbar = () => {

    const navigate = useNavigate();

    const handleGalleryImagesAddingDialogOpen = () => {

        store.dispatch(
            openGalleryImagesAddingDialog()
        );
    };

    /**
     * Méthode permettant d'ouvrir la page
     * du profil.
     */
    const handleProfileOpen = () => {

        navigate("/profile");
    };

    /**
     * Méthode permettant d'ouvrir la page
     * des souvenirs.
     */
    const handleMemoriesOpen = () => {

        navigate("/memories");
    };

    return (

        <div className="flex justify-center space-x-6 p-2 bg-white border-t border-gray-50 sticky bottom-0">

            <NavbarItem icon={<FaCat/>} onClick={handleMemoriesOpen}/>
            <NavbarItem icon={<FiPlus/>} onClick={handleGalleryImagesAddingDialogOpen}/>
            <NavbarItem icon={<CgProfile/>} onClick={handleProfileOpen}/>
        </div>
    );
};

export default GalleryNavbar;