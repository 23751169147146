import Button from "../SendFormButton/Button";
import {galleryService} from "../../service/GalleryService/GalleryService";
import {useState} from "react";

const GalleryHeader = () => {

    const [isDownloading, setIsDownloading] = useState(false);

    /**
     * Méthode permettant de télécharger toutes les
     * images de la galerie.
     */
    const downloadAllImages = () => {

        setIsDownloading(true);
        galleryService.downloadAllImages().then(() => {
            setIsDownloading(false);
        });
    };

    return (
        <div className="flex justify-center md:justify-end px-6 py-2 md:py-4 sticky top-0">
            <Button onClick={ downloadAllImages} label="Télécharger la galerie" loading={ isDownloading } />
        </div>
    );
};

export default GalleryHeader;