import api from "../../configuration/Api";

/**
 * Service permettant de gérer la galerie.
 */
class GalleryService {

    private apiUri = "/gallery";

    async downloadAllImages() {

        try {

            const response = await api.get(`${this.apiUri}/download-all-images`, {
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "images.zip"); // TODO : revoir le nom du fichier.
            document.body.appendChild(link);
            link.click();
            link.remove();

            return response;
        } catch (error) {

            throw error; // TODO : revoir.
        }
    }

    async getAllImages() {

        return api.get(`${this.apiUri}/all`).then((response) => {

            return response.data;
        });
    }

    async addImages(parameters: FormData) {

        return api.post(`${this.apiUri}/add-images`, parameters)
            .then((response) => {

                return response.data;
            });
    }
}

export const galleryService = new GalleryService();