import {User} from "../../model/User/User";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../Store";

type UserState = {
    connectedUser: User | null;
};

const initialState: UserState = {
    connectedUser: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setConnectedUser: (state, action: PayloadAction<User | null>) => {
            state.connectedUser = action.payload;
        }
    }
});

export const { setConnectedUser } = userSlice.actions;

export const selectConnectedUser = (state: RootState) => state.user.connectedUser;

export default userSlice.reducer;