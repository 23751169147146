import MemoryCard from "../MemoryCard/MemoryCard";
import React, {useEffect} from "react";
import {Memory} from "../../model/Memory/Memory";
import {memoryService} from "../../service/Memory/MemoryService";
import {useSelector} from "react-redux";
import {selectMemories, setMemories} from "../../store/Memory/MemorySlice";
import store from "../../store/Store";

/**
 * Composant permettant de représenter une liste de souvenirs.
 * @constructor
 */
const MemoryList = () => {

    const memories = useSelector(selectMemories);

    useEffect(() => {

        memoryService.getAllMemories().then((memories: Memory[]) => {

            const sortedMemories = memories.sort((a, b) => {

                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime();
            });

            store.dispatch(setMemories(sortedMemories));
        });
    }, []);

    return (
        <div className="flex flex-col items-center overflow-y-auto">

            {
                memories?.map((memory) => (

                    <MemoryCard key={ memory.id } memory={ memory }/>
                ))
            }
        </div>
    );
};

export default MemoryList;