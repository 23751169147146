import {useEffect} from "react";
import {Image as ImageFile} from "../../model/Image/Image";
import {galleryService} from "../../service/GalleryService/GalleryService";
import {useSelector} from "react-redux";
import {selectGalleryImages, setGalleryImages} from "../../store/Gallery/GallerySlice";
import store from "../../store/Store";
import GalleryImage from "../GalleryImage/GalleryImage";

/**
 * Composant permettant de représenter la liste d'images
 * de la galerie.
 *
 * @constructor
 */
const GalleryImageList = () => {

    const images = useSelector(selectGalleryImages);

    useEffect(() => {

        galleryService.getAllImages().then((images: ImageFile[]) => {

            store.dispatch(setGalleryImages(images));
        });

    }, []);

    return (
        <div className="flex-1 bg-white px-6 pb-6 overflow-y-auto">

            <div className="columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-4 [&>img:not(:first-child)]:mt-8 space-y-6">
                {images?.map((image) => (
                    <GalleryImage key={ image.id } fileName={ image.fileName } />
                ))}
            </div>
        </div>
    );
};

export default GalleryImageList;