import {ChangeEvent, useRef} from "react";
import { IoMdAdd } from "react-icons/io";

type ImageSelectorProps = {
    label: string;
    value: File[];
    onChange: Function;
    valid: boolean;
};

/**
 * Composant permettant de représenter un sélecteur
 * d'images.
 *
 * @param props props du composant.
 * @constructor
 */
const ImageSelector = (props: ImageSelectorProps) => {

    const imagesInputRef = useRef<HTMLInputElement | null>(null);

    /**
     * Méthode permettant de gérer les actions à réaliser
     * lorsque des fichiers sont sélectionnés.
     *
     * @param event événement représentant l'ajout des fichiers.
     */
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {

        if (event.target.files) {

            const filesArray = Array.from(event.target.files);
            props.onChange(filesArray);
        }
    };

    /**
     * Méthode permettant de définir le style du composant
     * dynamiquement.
     */
    const getStyle = () => {

        let baseStyle = "border rounded-md py-4 px-5";

        if (props.valid) {

            return `${baseStyle}`;
        }

        return `${baseStyle} border-red-500`;
    };

    const handleAddingButtonClick = () => {

        if (imagesInputRef.current) {

            imagesInputRef.current.click();
        }
    };

    return (

        <div className="space-y-1">
            <label>{ props.label }</label>
            <div className={ getStyle() }>
                <input
                    type="file"
                    accept="image/*"
                    multiple={ true }
                    ref={ imagesInputRef }
                    onChange={ handleInputChange }
                    className="hidden"
                />
                <button className="flex items-center space-x-1 hover:underline" onClick={ handleAddingButtonClick }>
                    <p className="text-sm">Sélectionner des images</p>
                    <IoMdAdd />
                </button>
            </div>
        </div>
    );
};

export default ImageSelector;