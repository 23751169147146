import {useState} from "react";
import {Image as ImageFile} from "../../model/Image/Image";
import MemoryImage from "../MemoryImage/MemoryImage";

type ImageCarouselProps = {
    images: ImageFile[];
};

/**
 * Composant permettant de représenter un carousel d'images.
 *
 * @param props props du composant.
 * @constructor
 */
const MemoryImageCarousel = (props: ImageCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === props.images.length - 1 ? 0 : prevIndex + 1));
    };

    const previousImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? props.images.length - 1 : prevIndex - 1));
    };

    return (
        <div className="relative w-full aspect-[1/1] bg-gray-100 rounded-md overflow-hidden flex justify-center">
            {
                props.images?.length > 1 &&

                <div
                    className="absolute top-1/2 left-2 text-white hover:text-gray-200 cursor-pointer z-10 text-4xl"
                    onClick={previousImage}
                >
                    &#9001;
                </div>
            }

            {
                props.images?.length > 1 &&

                <div
                    className="absolute top-1/2 right-2 text-white hover:text-gray-200 cursor-pointer z-10 text-4xl"
                    onClick={nextImage}
                >
                    &#9002;
                </div>
            }

            <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{transform: `translateX(-${currentIndex * 100}%)`}}
            >
                {props.images.map((image) => (
                    <MemoryImage
                        key={ image.id }
                        fileName={ image.fileName }
                    />
                ))}
            </div>
        </div>
    );
};

export default MemoryImageCarousel;
