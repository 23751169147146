import axios from "axios";
import { userService } from "../service/User/UserService";

const options = {
    baseURL: window._env_.REACT_APP_BOUBOUIMYSTERE_BACKEND,
    withCredentials: true
};

/**
 * Configuration des appels API.
 */
const api = axios.create(options);

let isRefreshing = false;
let pendingRequests: ((error?: any) => void)[] = []; // TODO : voir le type.

api.interceptors.response.use(
    response => response,
    async (error) => {

        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {

            if (!isRefreshing) {

                isRefreshing = true;

                try {

                    await userService.refreshToken();

                    pendingRequests.forEach(callback => callback());
                    pendingRequests = [];

                } catch (refreshError) {

                    pendingRequests.forEach(callback => callback(refreshError));
                    pendingRequests = [];

                    return Promise.reject(refreshError);
                } finally {

                    isRefreshing = false;
                }
            }

            return new Promise((resolve, reject) => {

                pendingRequests.push((error: any) => {

                    if (error) {

                        reject(error);
                    } else {

                        resolve(api(originalRequest));
                    }
                });
            });
        }

        return Promise.reject(error);
    }
);

export default api;
